
export default function Information() {
    return (
        <div className="content">
            <div className="marginParagraphAbout">

            <h1>ABOUT</h1>
            <p>ben dot place is a place to post and have fun with your friends.</p>
            <p>All data on this website is the legal property of their respective owner, and I take no ownership of any information on this website.</p>
            <p>All media, rap lyrics, and any combination of 0s and 1s are posted here under fair use.</p>
            <p>Your IP address will likely be logged if you are posting on this website, and sent to any 3 or 4 letter agency that requests it.</p>
            <p>Have fun and be yourself!</p>

        </div>
        </div>
    )
}